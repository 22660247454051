import React from 'react';
import { Card, CardActions, CardContent, IconButton, Tooltip, Typography, Grid, Box } from '@material-ui/core';
import { Casino, Delete, Edit, Refresh } from '@material-ui/icons';
import { DiceSet, RollResult, rollDice } from '../store/common';
import { RootState } from '../store';
import { connect, ConnectedProps } from 'react-redux';
import { deleteDiceSetAction, duplicateDiceSetAction } from '../store/diceSets/actions';
import { ConfirmDialog, DiceSetDisplay, EditDiceSet, HistoryDisplay } from '.';
import { useStyles } from '../styles';
import { addHistoryAction } from '../store/history/actions';

interface ComponentProps {
  diceSet: DiceSet
}

const mapStateToProps = (state: RootState) => ({
  selected: state.characters.selected,
  characters: state.characters.list,
  options: state.options,
  history: state.history.list,
});

const mapDispatchToProps = {
  duplicateDiceSet: (diceSet: DiceSet) => duplicateDiceSetAction(diceSet),
  deleteDiceSet: (id: string) => deleteDiceSetAction(id),
  addHistory: (rollResult: RollResult) => addHistoryAction(rollResult),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ComponentProps;

const DiceSetCard: React.FunctionComponent<Props> = ({ diceSet, selected, characters, options, history, duplicateDiceSet, deleteDiceSet, addHistory }) => {
  const [historyId, setHistoryId] = React.useState<string | undefined>();
  const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
  const [editOpen, setEditOpen] = React.useState<boolean>(false);
  const classes = useStyles();

  const handleEdit = () => {
    setEditOpen(true);
  };

  const handleDuplicate = () => {
    duplicateDiceSet(diceSet);
  }

  const handleDelete = () => {
    deleteDiceSet(diceSet.id);
  }

  const handleRoll = () => {
    const character = characters.find((item) => item.id === selected);
    const value = rollDice(diceSet, options, character);

    addHistory(value);
  }

  React.useEffect(() => {
    const item = [...history].reverse().find((item) => item.diceSet.id === diceSet.id);
    setHistoryId(item?.id);
  }, [history, diceSet.id]);

  return (
    <>
      <Card className={classes.card}>
        <CardContent style={{ paddingBottom: 0 }}>
          <Typography className={classes.title} component="div" gutterBottom>
            <Box display="flex">
              <Box flexGrow={1}>
                {diceSet.name}
              </Box>
              {(diceSet.multiplier > 1) && (
                <Box marginLeft='16px'>
                  Crit: {diceSet.critical < 20 ? `${diceSet.critical}-20` : '20'} x{diceSet.multiplier}
                </Box>
              )}
            </Box>
          </Typography>
          <DiceSetDisplay diceSet={diceSet} />
          <HistoryDisplay historyId={historyId} />
        </CardContent>
        <CardActions style={{ paddingTop: 0 }}>
          <Grid container justify="space-evenly">
            <Grid item>
              <Tooltip title="Roll" arrow>
                <IconButton onClick={handleRoll}><Casino /></IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Edit" arrow>
                <IconButton onClick={handleEdit}><Edit /></IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Duplicate" arrow>
                <IconButton onClick={handleDuplicate}><Refresh /></IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Delete" arrow>
                <IconButton onClick={() => setConfirmOpen(true)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <ConfirmDialog title="Delete Dice Set?" open={confirmOpen} setOpen={setConfirmOpen} onConfirm={handleDelete}>
        <>Are you sure you want to delete <span className={classes.primary}>{diceSet.name}</span>?</>
      </ConfirmDialog>
      <EditDiceSet open={editOpen} diceSet={diceSet} setOpen={setEditOpen} />
    </>
  )
}

export default connector(DiceSetCard);
