import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      padding: theme.spacing(2),
      marginTop: 64,
    },
    card: {
      minWidth: 200,
      maxWidth: 400,
      textAlign: "center",
      backgroundColor: theme.palette.background.default,
    },
    quickRoll: {
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      fontSize: 14,
      color: theme.palette.text.hint,
      textAlign: "start",
    },
    primary: {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
    },
    grow: {
      flexGrow: 1,
    },
    appTitle: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    roll: {
      cursor: 'pointer',
      padding: theme.spacing(1),
      margin: `${theme.spacing(1)}px auto`,
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.contrastText,
      fontSize: theme.typography.h4.fontSize,
      minWidth: 64,
      borderRadius: 16,
      width: 'fit-content',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    critical: {
      backgroundColor: theme.palette.secondary.contrastText,
      color: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: '#d7d7d7',
      },
    },
    tabPaper: {
      flexGrow: 1,
    },
    marginLeft: {
      marginLeft: theme.spacing(1),
    },
    marginRight: {
      marginRight: theme.spacing(1),
    },
    center: {
      textAlign: 'center',
    },
    error: {
      color: theme.palette.error.main,
    },
    success: {
      color: theme.palette.success.main,
    },
    version: {
      marginLeft: theme.spacing(2),
      color: theme.palette.text.hint,
    },
    rollResult : {
      '&:not(:last-child):after': {
        content: '"+"',
        color: theme.palette.text.hint,
        marginLeft: 1,
        marginRight: 1,
      }
    },
    damageType: {
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      textTransform: 'initial',
      '&:hover': {
        textDecoration: 'underline',
      },
      '&:not(:last-child):after': {
        content: '"•"',
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        marginLeft: 4,
        marginRight: 4,
      }
    },
    immune: {
      color: theme.palette.text.disabled,
    },
    vulnerable: {
      color: theme.palette.error.main
    },
    pointer: {
      cursor: 'pointer',
    },
  })
);
