import {
  History,
  RESET_HISTORY,
  CLEAR_HISTORY,
  ADD_HISTORY,
  UPDATE_HISTORY,
  DELETE_HISTORY,
  HistoryActionTypes,
} from './types';

const initialState: History = {
  max: 20,
  list: [],
};

export function historyReducer(state = initialState, action: HistoryActionTypes): History {
  switch (action.type) {
    case RESET_HISTORY:
      return initialState;

    case CLEAR_HISTORY:
      return {
        ...state,
        list: []
      };

    case ADD_HISTORY:
      return {
        ...state,
        list: [ ...(state.list.length >= state.max ? state.list.slice(1) : state.list), action.rollResult],
      };

    case UPDATE_HISTORY:
      if (state.list.find((item) => item.id === action.rollResult.id)) {
        return {
          ...state,
          list: state.list.map((item) => (item.id === action.rollResult.id ? action.rollResult : item)),
        };
      };

      return state;

    case DELETE_HISTORY:
      return {
        ...state,
        list: state.list.filter((item) => item.id !== action.id),
      };

    default:
      return state;
  }
};
