import { RollResult } from '../common';

export const RESET_HISTORY = 'RESET_HISTORY';
export const CLEAR_HISTORY = 'CLEAR_HISTORY';
export const ADD_HISTORY = 'ADD_HISTORY';
export const UPDATE_HISTORY = 'UPDATE_HISTORY';
export const DELETE_HISTORY = 'DELETE_HISTORY';

export interface History {
  max: number
  list: RollResult[]
};

interface ResetHistoryAction {
  type: typeof RESET_HISTORY
}

interface ClearHistoryAction {
  type: typeof CLEAR_HISTORY
}

interface AddHistoryAction {
  type: typeof ADD_HISTORY
  rollResult: RollResult
}

interface UpdateHistoryAction {
  type: typeof UPDATE_HISTORY
  rollResult: RollResult
}

interface DeleteHistoryAction {
  type: typeof DELETE_HISTORY
  id: string
}

export type HistoryActionTypes = 
  ResetHistoryAction |
  ClearHistoryAction |
  AddHistoryAction |
  UpdateHistoryAction |
  DeleteHistoryAction;
