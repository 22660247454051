import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import { RootState } from '../store';
import { DamageTypes, DiceType, RollResult, Vulnerability } from '../store/common';
import clsx from 'clsx';
import { updateHistoryAction } from '../store/history/actions';

interface ComponentProps {
  historyId?: string
  readOnly?: boolean
}

const mapStateToProps = (state: RootState) => ({
  history: state.history.list,
  allowNegativeRolls: state.options.allowNegativeRolls,
});

const mapDispatchToProps = {
  updateHistory: (rollResult: RollResult) => updateHistoryAction(rollResult),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ComponentProps;

const HistoryDisplay: React.FunctionComponent<Props> = ({ historyId, readOnly, history, allowNegativeRolls, updateHistory }) => {
  const classes = useStyles();
  const [total, setTotal] = React.useState<number>(0);
  const [damageTypes] = React.useState(new Map<string, number>());

  const [rollResult, setRollResult] = React.useState<RollResult | undefined>();
  
  React.useEffect(() => {
    setRollResult(history.find((item) => item.id === historyId));
  }, [historyId, history]);

  React.useEffect(() => {
    function updateMap(map: Map<string, number>, key: string | undefined, value: number) {
      const previous = map.get(key ?? '');
      map.set(key ?? '', value + (previous ?? 0));
    };

    let value = 0;
    damageTypes.clear();

    if (rollResult) {
      for (const diceResult of rollResult.diceResults) {
        const vulnerability = rollResult.vulnerabilities.find((item) => item.key === diceResult.name);

        if (diceResult.diceValue.type === DiceType.roll) {
          const dmg = diceResult.value * (rollResult.isCritical ? rollResult.diceSet.multiplier : 1) * (vulnerability?.value ?? 1);
          value += dmg;

          updateMap(damageTypes, diceResult.name, dmg);
        } else {
          const dmg = diceResult.value * (vulnerability?.value ?? 1);

          value += dmg;
          updateMap(damageTypes, diceResult.name, dmg);
        }
      }
    }

    if (!allowNegativeRolls && value <= 0) value = 1;

    setTotal(value);
  }, [rollResult, allowNegativeRolls, damageTypes]);

  if (!rollResult) {
    return (<></>);
  }

  const getVulnerability = (damageType: string) => {
    const previous = rollResult.vulnerabilities.find((item) => item.key === damageType);

    return previous?.value ?? 1;
  }

  const toggleVulnerability = (damageType: string) => {
    const previous = rollResult.vulnerabilities.find((item) => item.key === damageType);

    if (previous) {
      updateHistory({ ...rollResult, vulnerabilities: rollResult.vulnerabilities.map((item: Vulnerability) => item.key === damageType ? { key: item.key, value: (item.value + 1) % 3 } as Vulnerability : item)})
    } else {
      updateHistory({ ...rollResult, vulnerabilities: [...rollResult.vulnerabilities, { key: damageType as DamageTypes, value: 2 }]});
    }
  }

  const toggleCritical = () => {
    updateHistory({ ...rollResult, isCritical: !rollResult.isCritical });
  }

  const damages = Array.from(damageTypes || []).filter(([key]) => key !== '').map(([key, value]) => ({ key, value }));

  return (
    <>
      <div className={clsx(classes.roll, rollResult.isCritical && classes.critical)} onClick={() => !readOnly && toggleCritical()}>
        {total}
      </div>
      <div>
        {damages.map(({ key, value }) => (
          <span
            key={key}
            className={clsx(classes.damageType, getVulnerability(key) === 0 && classes.immune, getVulnerability(key) === 2 && classes.vulnerable)}
            onClick={() => !readOnly && toggleVulnerability(key)}
          >
            {`${key}: ${value}`}
          </span>
        ))}
      </div>
      <div>
        <Typography color="textSecondary" variant="caption">
          {rollResult.diceResults.map((diceResult, index) => (
            <span key={diceResult.id + index.toString()} className={classes.rollResult} style={{ color: diceResult.color }}>
              {diceResult.label ? `${diceResult.label}: ${diceResult.value}` : diceResult.value}
            </span>
          ))}
        </Typography>
        {(rollResult.isCritical && rollResult.diceSet.multiplier > 1) && (
          <Typography color="textSecondary" variant="caption" style={{ marginLeft: 8 }}>
            <span>{`x${rollResult.diceSet.multiplier}`}</span>
          </Typography>
        )}
      </div>
    </>
  );
};

export default connector(HistoryDisplay);
