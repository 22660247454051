export interface Attribute {
  value: string
  label: string
};

export type DamageTypes =
  '' | 'PHYSICAL' |
  'BLUDGEONING' | 'PIERCING' | 'SLASHING' |
  'ACID' | 'COLD' | 'ELECTRICITY' | 'FIRE' | 'SONIC' |
  'POSITIVE' | 'NEGATIVE' |
  'FORCE' | 
  'GOOD' | 'NEUTRAL' | 'EVIL' | 'LAW' | 'CHAOS' |
  'POISON' | 'MENTAL' | 'BLEED' | 'NONLEATHAL' | 'PRECISION';

export type DamageGroup = 'Physical' | 'Elemental' | 'Positive/Negative' | 'Force' | 'Alignment' | 'Mental' | 'Poison' | 'Bleed' | 'Nonlethal' | 'Precision';

export interface DamageType {
  value: DamageTypes
  label: string
  group: DamageGroup
}

export interface Die { 
  value: number
  label: string
  color: string
};

export enum AttributeType {
  STR = 'STR',
  DEX = 'DEX',
  CON = 'CON',
  INT = 'INT',
  WIS = 'WIS',
  CHA = 'CHA',
};

export enum DiceType {
  roll = 'roll',
  modifier = 'modifier',
  attribute = 'attribute',
  baseAttack = 'baseAttack',
};

export interface Roll {
  count: number
  die: number
};

interface DiceRoll {
  type: typeof DiceType.roll
  count: number
  die: number
}

interface ModifierRoll {
  type: typeof DiceType.modifier
  modifier: number
}

interface AttributeRoll {
  type: typeof DiceType.attribute
  attribute: AttributeType
}

interface BaseAttackRoll {
  type: typeof DiceType.baseAttack
}

export type DiceValue = DiceRoll | ModifierRoll | AttributeRoll | BaseAttackRoll;

export interface Dice {
  id: string
  name?: string
  diceValue: DiceValue
};

export type Multiplier = 1 | 2 | 3 | 4;

export interface DiceSet {
  id: string
  name: string
  critical: number
  multiplier: Multiplier
  dice: Array<Dice>
};

export interface DiceResult extends Dice {
  value: number
  label?: string
  color?: string
}

export type VulnerabilityAmount = 0 | 1 | 2;

export type Vulnerability = {
  key: DamageTypes
  value: VulnerabilityAmount
}

export interface RollResult {
  id: string
  timestamp: Date
  diceSet: DiceSet
  character?: Character
  vulnerabilities: Array<Vulnerability>
  diceResults: Array<DiceResult>
  isCritical: boolean
};

export interface Health {
  max: number
  temporary: number
  current: number
}

export interface Attributes {
  STR: number
  DEX: number
  CON: number
  INT: number
  WIS: number
  CHA: number
}

export interface SavingThrows {
  FORT: number
  REF: number
  WILL: number
}

export interface Character {
  id: string
  name: string
  health: Health
  baseAttack: number
  initiative: number
  attributes: Attributes
  savingThrows: SavingThrows
};

export function getBonus(value: number): number {
  return (value - 10) / 2;
};

export type FickleAttack = 0 | 1 | 2 | 3;
